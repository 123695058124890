<template>
    <div>
        <w-snackbar-queue
            :items="snackbarItems"
            @remove="removeSnackbarItem"
            right
        ></w-snackbar-queue>
        <centered-layout :loading="pageLoading">
            <w-form-message :logo-path="clientLogo" :title="title" v-if="codeInvalid">
                <p>The MFA code has not been found or has expired.</p>
                <template slot="actions">
                    <router-link :to="{name: loginPage}" slot="actions">Do you want to login?</router-link>
                </template>
            </w-form-message>
            <w-multi-factor-auth-form
                :errors="multifactorAuthErrors"
                :fallback-options="userFallbackOptions"
                :item="formItem"
                :loading="loading"
                :processing-fallback="processingFallback"
                :title="title"
                @fallback:email="handleEmailFallback"
                @fallback:sms="handleSmsFallback"
                @recover="handleRecovery"
                @submit="submit"
                v-else-if="!loggedIn"
            >
                <v-flex slot="top" xs12>
                    <img :src="clientLogo" class="logo" v-if="clientLogo">
                </v-flex>
            </w-multi-factor-auth-form>
            <w-form-message :logo-path="clientLogo" :title="title" v-else>
                <p>You are now being redirected. If you are not redirected automatically,
                    <a :href="redirectUrlWithQuery">click here.</a>
                </p>
                <v-progress-linear indeterminate></v-progress-linear>
            </w-form-message>
        </centered-layout>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import WFormMessage from '@wf/components/WFormMessage'
    import WMultiFactorAuthForm from '@wf/components/WMultiFactorAuthForm'
    import WSnackbarQueue from '@wf/components/WSnackbarQueue'
    import WSnackbarMixin from '@wf/mixins/w-snackbar-mixin'
    import LoginMixin from '@/mixins/login-mixin'
    import {LOGIN_PAGE, RECOVERY_PAGE} from '@/router/route-names'

    export default {
        name: 'MultiFactorAuthPage',
        mixins: [LoginMixin, WSnackbarMixin],
        metaInfo () {
            const vm = this
            return {
                title: vm.loading ? 'Authorising Request' : vm.title
            }
        },
        data: () => ({
            codeInvalid: false,
            pageLoading: false,
            loading: false,
            processingFallback: false,
            loggedIn: false,
            multifactorAuthErrors: [],
            recoveryPage: RECOVERY_PAGE,
            loginPage: LOGIN_PAGE
        }),
        props: {
            emailAddress: {
                type: String,
                required: true
            },
            accountCode: {
                type: String,
                required: true
            },
            userId: {
                type: String,
                required: true
            },
            code: {
                type: String,
                required: true
            }
        },
        components: {
            CenteredLayout,
            WFormMessage,
            WMultiFactorAuthForm,
            WSnackbarQueue
        },
        methods: {
            ...mapActions('auth', ['checkMfaLoginCode', 'loginMfa', 'sendEmailFallbackCode', 'sendSmsFallbackCode']),
            handleEmailFallback () {
                const vm = this
                vm.processingFallback = true
                vm.sendEmailFallbackCode({
                    userId: vm.userId,
                    code: vm.code,
                    accountCode: vm.accountCode
                }).then(() => {
                    vm.processingFallback = false
                    vm.printSnackbarMessage()
                }, () => {
                    vm.processingFallback = false
                })
            },
            handleSmsFallback () {
                const vm = this
                vm.processingFallback = true
                vm.sendSmsFallbackCode({
                    userId: vm.userId,
                    code: vm.code,
                    accountCode: vm.accountCode
                }).then(() => {
                    vm.processingFallback = false
                    vm.printSnackbarMessage()
                }, () => {
                    vm.processingFallback = false
                })
            },
            handleRecovery () {
                const vm = this
                vm.$router.push({
                    name: vm.recoveryPage,
                    params: {
                        userId: vm.userId,
                        code: vm.code
                    }
                }).catch(() => {
                    // Do nothing
                })
            },
            submit (data) {
                const vm = this
                vm.loading = true
                vm.loginMfa(data).then((response) => {
                    vm.handleLoginResponse(response)
                    vm.multifactorAuthErrors = []
                    vm.loggedIn = true
                }, (response) => {
                    vm.loading = false
                    vm.multifactorAuthErrors = response.errors
                })
            },
            printSnackbarMessage (message, color = 'success') {
                const vm = this
                vm.addSnackbarItem({
                    uuid: vm.$uuid(),
                    color,
                    message: message ? message : 'Your code has been sent'
                })
            }
        },
        computed: {
            ...mapGetters('auth', ['clientLogo', 'clientName']),
            ...mapGetters('user', ['userFallbackOptions']),
            title () {
                return `Two-Factor Authentication - ${this.clientName}`
            },
            formItem () {
                const vm = this
                return {
                    account_code: vm.accountCode,
                    email_address: vm.emailAddress,
                    user_id: vm.userId,
                    code: vm.code
                }
            }
        },
        created () {
            const vm = this
            vm.pageLoading = true
            vm.checkMfaLoginCode({
                accountCode: vm.accountCode,
                userId: vm.userId,
                code: vm.code
            }).then(() => {
                vm.pageLoading = false
            }, () => {
                vm.codeInvalid = true
                vm.pageLoading = false
            })
        }
    }
</script>
